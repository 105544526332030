<template>
        <main class="card invision-card elevate-2 invision-top borderless container padding">
            <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
                <slot />
            </b-col>
            </b-row>
        </main>
</template>
<script>

export default {
  props: ['width'],
};

</script>
<style scoped>
main {
    transition: all ease .3s;
}

.padding{
    padding: 36px;
    font-weight: 400;
}

</style>
